<template>
  <main id="Themes">
    <CContainer>
      <CCard>
        <CCardHeader>
          <h5 class="mb-0">{{ $t('Navigation.System/Themes') }}</h5>
        </CCardHeader>
        <CCardBody>
          <CTabs variant="pills" :vertical="{ navs: 'col-xl-2 col-md-3 col-sm-4 col-12', content: 'col-xl-10 col-md-9 col-sm-8 col-12' }">
            <CTab v-if="Features.includes('Carousel')" :title="$t('System/Themes.MainCarousel')" active>
              <h5>{{ $t('System/Themes.MainCarousel') }}</h5>
              <hr>
              <CAlert color="info">
                {{ $t('Message.System/DragAndChangePosition') }}
              </CAlert>
              <CCard>
                <CCardHeader class="d-flex align-items-center">
                  <div class="flex-grow-1" style="cursor: pointer;" @click="(CarouselAccordion = CarouselAccordion === 'Default' ? false : 'Default')">
                    {{ $t('Locale.Default') }}
                  </div>
                </CCardHeader>
                <CCollapse :show="CarouselAccordion === 'Default'">
                  <CCardBody>
                    <Carousel key="CarouselDefault" :data.sync="ThemesData.Carousel" :title="$t('System/Themes.MainCarousel')" @save="SetData('Carousel')" />
                  </CCardBody>
                </CCollapse>
              </CCard>
              <template v-if="$store.state.user.permission.MutipleLocale">
                <CCard v-for="(locale, index) in $store.state.user.permission.Locales" :key="'Carousel_' + locale">
                  <CCardHeader class="d-flex align-items-center">
                    <div class="flex-grow-1" style="cursor: pointer;" @click="(CarouselAccordion = CarouselAccordion === index ? false : index)">
                      {{ $t('Locale.' + locale) }}
                    </div>
                  </CCardHeader>
                  <CCollapse :show="CarouselAccordion === index">
                    <CCardBody>
                      <Carousel :key="'Carousel_' + locale" :data.sync="ThemesData['Carousel_' + locale]" @save="SetData('Carousel_' + locale)" />
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </template>
            </CTab>
            <CTab v-if="Features.includes('CarouselMobile')" :title="$t('System/Themes.MainCarouselMobile')">
              <h5>{{ $t('System/Themes.MainCarouselMobile') }}</h5>
              <hr>
              <CAlert color="info">
                {{ $t('Message.System/DragAndChangePosition') }}
              </CAlert>
              <CCard>
                <CCardHeader class="d-flex align-items-center">
                  <div class="flex-grow-1" style="cursor: pointer;" @click="(CarouselAccordion = CarouselAccordion === 'Default' ? false : 'Default')">
                    {{ $t('Locale.Default') }}
                  </div>
                </CCardHeader>
                <CCollapse :show="CarouselAccordion === 'Default'">
                  <CCardBody>
                    <Carousel key="CarouselDefault" :data.sync="ThemesData.CarouselMobile" @save="SetData('CarouselMobile')" />
                  </CCardBody>
                </CCollapse>
              </CCard>
              <template v-if="$store.state.user.permission.MutipleLocale">
                <CCard v-for="(locale, index) in $store.state.user.permission.Locales" :key="'CarouselMobile_' + locale">
                  <CCardHeader class="d-flex align-items-center">
                    <div class="flex-grow-1" style="cursor: pointer;" @click="(CarouselAccordion = CarouselAccordion === index ? false : index)">
                      {{ $t('Locale.' + locale) }}
                    </div>
                  </CCardHeader>
                  <CCollapse :show="CarouselAccordion === index">
                    <CCardBody>
                      <Carousel :key="'CarouselMobile_' + locale" :data.sync="ThemesData['CarouselMobile_' + locale]" @save="SetData('CarouselMobile_' + locale)" />
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </template>
            </CTab>
            <CTab v-if="Features.includes('CarouselBottom')" :title="$t('System/Themes.BottomCarousel')">
              <h5>{{ $t('System/Themes.BottomCarousel') }}</h5>
              <hr>
              <CAlert color="info">
                {{ $t('Message.System/DragAndChangePosition') }}
              </CAlert>
              <CCard>
                <CCardHeader class="d-flex align-items-center">
                  <div class="flex-grow-1" style="cursor: pointer;" @click="(CarouselAccordion = CarouselAccordion === 'Default' ? false : 'Default')">
                    {{ $t('Locale.Default') }}
                  </div>
                </CCardHeader>
                <CCollapse :show="CarouselAccordion === 'Default'">
                  <CCardBody>
                    <Carousel key="CarouselDefault" :data.sync="ThemesData.CarouselBottom" @save="SetData('CarouselBottom')" />
                  </CCardBody>
                </CCollapse>
              </CCard>
              <template v-if="$store.state.user.permission.MutipleLocale">
                <CCard v-for="(locale, index) in $store.state.user.permission.Locales" :key="'CarouselBottom_' + locale">
                  <CCardHeader class="d-flex align-items-center">
                    <div class="flex-grow-1" style="cursor: pointer;" @click="(CarouselAccordion = CarouselAccordion === index ? false : index)">
                      {{ $t('Locale.' + locale) }}
                    </div>
                  </CCardHeader>
                  <CCollapse :show="CarouselAccordion === index">
                    <CCardBody>
                      <Carousel :key="'CarouselBottom_' + locale" :data.sync="ThemesData['CarouselBottom_' + locale]" @save="SetData('CarouselBottom_' + locale)" />
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </template>
            </CTab>
            <CTab v-if="Features.includes('Marquee')" :title="$t('System/Themes.Marquee')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Themes.Marquee') }}
                <div class="card-header-actions d-flex">
                  <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
                </div>
              </h5>
              <hr>
              <CCard>
                <CCardBody>
                  <div>
                    <h5 class="text-info">
                      跑馬燈列表
                    </h5>
                    <div class="mt-4">
                      <CCard v-for="(item, key) in ThemesData.Marquee" :key="key">
                        <CButton
                          block
                          color="link"
                          class="text-left shadow-none card-header"
                          @click="(Accordion = Accordion === key ? false : key)"
                        >
                          <h5 class="m-0">【{{ NowChooseLanguage === 'default' ? item.Badge : item.LanguageData[NowChooseLanguage].Badge }}】{{ NowChooseLanguage === 'default' ? item.Text : item.LanguageData[NowChooseLanguage].Text }}</h5>
                        </CButton>
                        <CCollapse :show="Accordion === key">
                          <CCardBody>
                            <template v-if="NowChooseLanguage === 'default'">
                              <CInput
                                :label="'跑馬燈標語'"
                                v-model="item.Badge"
                                size="sm"
                                type="text" />
                              <CInput
                                :label="'跑馬燈文字'"
                                v-model="item.Text"
                                size="sm"
                                type="text" />
                            </template>
                            <template v-else>
                              <CInput
                                :label="'跑馬燈標語'"
                                v-model="item.LanguageData[NowChooseLanguage].Badge"
                                size="sm"
                                type="text" />
                              <CInput
                                :label="'跑馬燈文字'"
                                v-model="item.LanguageData[NowChooseLanguage].Text"
                                size="sm"
                                type="text" />
                            </template>
                            <CInput
                              :label="$t('Global.Link')"
                              v-model="item.Link"
                              size="sm"
                              type="text" />
                            <CSelect :label="$t('Global.OpenTarget')" :options="TargetOption" size="sm" v-model="item.Target" :value.sync="item.Target" :placeholder="$t('Global.PleaseSelect')"/>
                            <CButton color="danger" class="px-4 mt-3 mr-2" @click="ThemesData.Marquee.splice(key, 1)" size="sm">
                              {{ $t('Global.Remove') }}
                            </CButton>
                          </CCardBody>
                        </CCollapse>
                      </CCard>
                    </div>
                    <CButton color="success" class="px-4 mt-3 mr-2" @click="SetData('Marquee')" size="sm">
                      {{ $t('Global.Confirm') }}
                    </CButton>
                    <CButton color="info" class="px-4 mt-3" @click="PushData('Marquee')" size="sm">
                      {{ $t('Global.Add') + $t('System/Themes.Marquee') }}
                    </CButton>
                  </div>
                </CCardBody>
              </CCard>
              <CCard>
                <CCardBody>
                  <div>
                    <h5 class="text-info">
                      跑馬燈設定
                    </h5>
                    <div>
                      可設定跑馬燈的背景顏色及循環週期。
                    </div>
                    <div class="mt-4">
                      <CInput :label="'背景顏色'" v-model="ThemesData.MarqueeConfig.Color">
                        <template #prepend>
                          <CButton type="button" color="secondary" @click="ColorPalette = !ColorPalette">
                            <CIcon name="cil-color-palette" class="my-0" />
                          </CButton>
                          <ColorPicker v-if="ColorPalette" v-model="SelectColor" class="position-absolute" @input="ThemesData.MarqueeConfig.Color = SelectColor.hex;" />
                        </template>
                      </CInput>
                      <CInput :label="'循環週期'" type="number" v-model="ThemesData.MarqueeConfig.Duration" description="單位為毫秒(ms)，預設設定為5000" />
                    </div>
                    <CButton color="success" class="px-4 mt-3" @click="SetData('MarqueeConfig')" size="sm">
                      {{ $t('Global.Update') }}
                    </CButton>
                  </div>
                </CCardBody>
              </CCard>
            </CTab>
            <CTab v-if="Features.includes('PopUp')" :title="$t('System/Themes.PopUp')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Themes.PopUp') }}
              </h5>
              <hr>
              <CCard>
                <CCardHeader class="d-flex align-items-center">
                  <div class="flex-grow-1" style="cursor: pointer;" @click="(PopAccordion = PopAccordion === 'Default' ? false : 'Default')">
                    {{ $t('Locale.Default') }}
                  </div>
                </CCardHeader>
                <CCollapse :show="PopAccordion === 'Default'">
                  <CCardBody v-if="ThemesData.PopUp">
                    <div class="w-100 d-flex flex-column my-2 mb-4">
                      <label>啟用彈出視窗</label>
                      <CSwitch color="success" :checked.sync="ThemesData.PopUp.Enable" />
                      <label class="mt-3">圖片</label>
                      <CRow>
                        <CCol lg="4" md="6">
                          <div v-if="ThemesData.PopUp.Image && ThemesData.PopUp.Image.URL" class="position-relative CarouselItem">
                            <img v-lazy="ThemesData.PopUp.Image.URL" class="img-fluid" />
                            <div class="CarouselTools">
                              <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('PopUp', 'Image')">
                                <CIcon name="cil-pencil" />
                              </CButton>
                            </div>
                          </div>
                          <div v-else class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                            <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                            <div class="CarouselTools">
                              <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('PopUp', 'Image')">
                                <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                              </CButton>
                            </div>
                          </div>
                        </CCol>
                      </CRow>
                      <CInput
                        :label="$t('Global.Link')"
                        class="mt-3"
                        v-model="ThemesData.PopUp.Link"
                        type="text" />
                      <CSelect :label="$t('Global.OpenTarget')" :options="TargetOption" v-model="ThemesData.PopUp.Target" :value.sync="ThemesData.PopUp.Target" :placeholder="$t('Global.PleaseSelect')"/>
                    </div>
                  </CCardBody>
                  <CCardFooter>
                    <CButton color="success" @click="SetData('PopUp')">
                      {{ $t('Global.Update') }}
                    </CButton>
                  </CCardFooter>
                </CCollapse>
              </CCard>
              <template v-if="$store.state.user.permission.MutipleLocale">
                <CCard v-for="(locale, index) in $store.state.user.permission.Locales" :key="'PopUp_' + locale">
                  <CCardHeader class="d-flex align-items-center">
                    <div class="flex-grow-1" style="cursor: pointer;" @click="(PopAccordion = PopAccordion === index ? false : index)">
                      {{ $t('Locale.' + locale) }}
                    </div>
                  </CCardHeader>
                  <CCollapse :show="PopAccordion === index">
                    <CCardBody v-if="ThemesData['PopUp_' + locale]">
                      <div class="w-100 d-flex flex-column my-2 mb-4">
                        <label>啟用彈出視窗</label>
                        <CSwitch color="success" :checked.sync="ThemesData['PopUp_' + locale].Enable" />
                        <label class="mt-3">圖片</label>
                        <CRow>
                          <CCol lg="4" md="6">
                            <div v-if="ThemesData['PopUp_' + locale].Image && ThemesData['PopUp_' + locale].Image.URL" class="position-relative CarouselItem">
                              <img v-lazy="ThemesData['PopUp_' + locale].Image.URL" class="img-fluid" />
                              <div class="CarouselTools">
                                <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('PopUp_' + locale, 'Image')">
                                  <CIcon name="cil-pencil" />
                                </CButton>
                              </div>
                            </div>
                            <div v-else class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                              <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                              <div class="CarouselTools">
                                <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('PopUp_' + locale, 'Image')">
                                  <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                                </CButton>
                              </div>
                            </div>
                          </CCol>
                        </CRow>
                        <CInput
                          :label="$t('Global.Link')"
                          class="mt-3"
                          v-model="ThemesData['PopUp_' + locale].Link"
                          type="text" />
                        <CSelect :label="$t('Global.OpenTarget')" :options="TargetOption" v-model="ThemesData['PopUp_' + locale].Target" :value.sync="ThemesData['PopUp_' + locale].Target" :placeholder="$t('Global.PleaseSelect')"/>
                      </div>
                    </CCardBody>
                    <CCardFooter>
                      <CButton color="success" @click="SetData('PopUp_' + locale)">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardFooter>
                  </CCollapse>
                </CCard>
              </template>
            </CTab>
            <CTab v-if="Features.includes('Menu')" :title="$t('System/Themes.HeaderNavigation')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Themes.HeaderNavigation') }}
              </h5>
              <hr>

              <CCard>
                <CCardHeader class="d-flex align-items-center">
                  <div class="flex-grow-1" style="cursor: pointer;" @click="(PopAccordion = PopAccordion === 'Default' ? false : 'Default')">
                    {{ $t('Locale.Default') }}
                  </div>
                </CCardHeader>
                <CCollapse :show="PopAccordion === 'Default'">
                  <CCardBody v-if="ThemesData.Menu">
                    <Draggable
                      v-model="ThemesData.Menu"
                      class="list-group"
                      tag="ul"
                      v-bind="DragOptions"
                      @start="drag = true"
                      @end="drag = false"
                    >
                      <CCard v-for="(item, index) in ThemesData.Menu" :key="index">
                        <CCardHeader class="d-flex align-items-center">
                          <div class="flex-grow-1" style="cursor: pointer;" @click="(MenuAccordion = MenuAccordion === index ? false : index)">
                            {{ item.Name }}
                          </div>
                          <CButton size="sm" color="danger" @click="DeleteMenuItem(`${index}`)">{{ $t('Global.Delete') }}</CButton>
                        </CCardHeader>
                        <CCollapse :show="MenuAccordion === index">
                          <CCardBody class="d-flex">
                            <CRow class="w-100">
                              <CCol>
                                <CInput v-model="item.Name" :label="$t('System/Themes.Menu.Name')"/>
                                <CInput v-model="item.SubTitle" :label="$t('System/Themes.Menu.SubTitle')"/>
                                <CInput v-model="item.URL" :label="$t('System/Themes.Menu.URL')"/>
                                <CSelect :value.sync="item.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                <div class="w-100 d-flex flex-column my-2 mb-4">
                                  <label for="MenuSwitch">啟用選單</label>
                                  <CSwitch
                                    color="success"
                                    shape="pill"
                                    :checked.sync="item.Open"
                                    id="MenuSwitch"
                                  />
                                </div>
                                <CButton block color="info" @click="AddSubMenuItem(index)">{{ $t('Global.Add') }}{{ $t('System/Themes.Menu.SubMenu') }}</CButton>
                              </CCol>
                              <CCol>
                                <h5>{{ $t('System/Themes.Menu.SubMenu') }}</h5>
                                <Draggable
                                  v-model="item.SubMenu"
                                  v-bind="DragOptions"
                                  @start="drag = true"
                                  @end="drag = false"
                                >
                                  <CCard v-for="(subItem, subIndex) in item.SubMenu" :key="subIndex">
                                    <CCardHeader class="d-flex align-items-center">
                                      <div class="flex-grow-1" style="cursor: pointer;" @click="(SubMenuAccordion = SubMenuAccordion === `${index}-${subIndex}` ? false : `${index}-${subIndex}`)">
                                        {{ subItem.Name }}
                                      </div>
                                      <CButton color="danger" size="sm" @click="DeleteMenuItem(`${index}-${subIndex}`)">{{ $t('Global.Delete') }}</CButton>
                                    </CCardHeader>
                                    <CCollapse :show="SubMenuAccordion === `${index}-${subIndex}`">
                                      <CCardBody class="d-flex flex-column">
                                        <CInput v-model="subItem.Name" :label="$t('System/Themes.Menu.Name')"/>
                                        <CInput v-model="subItem.URL" :label="$t('System/Themes.Menu.URL')"/>
                                        <CSelect :value.sync="subItem.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                        <label for="MenuSwitch">啟用選單</label>
                                        <CSwitch
                                          color="success"
                                          shape="pill"
                                          :checked.sync="subItem.Open"
                                          id="MenuSwitch"
                                        />
                                      </CCardBody>
                                    </CCollapse>
                                  </CCard>
                                </Draggable>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCollapse>
                      </CCard>
                    </Draggable>
                  </CCardBody>
                  <CCardFooter class="d-flex justify-content-between">
                    <CButton color="info" class="px-4 mt-3 mr-1" @click="AddMenuItem('default')">{{ $t('Global.Add') }}</CButton>
                    <CButton color="success" class="px-4 mt-3" @click="SetData('Menu')">
                      {{ $t('Global.Update') }}
                    </CButton>
                  </CCardFooter>
                </CCollapse>
              </CCard>
              <template v-if="$store.state.user.permission.MutipleLocale">
                <CCard v-for="(locale, index) in $store.state.user.permission.Locales" :key="'Menu_' + locale">
                  <CCardHeader class="d-flex align-items-center">
                    <div class="flex-grow-1" style="cursor: pointer;" @click="(PopAccordion = PopAccordion === index ? false : index)">
                      {{ $t('Locale.' + locale) }}
                    </div>
                  </CCardHeader>
                  <CCollapse :show="PopAccordion === index">
                    <CCardBody v-if="ThemesData['Menu_' + locale]">
                      <Draggable
                        v-model="ThemesData['Menu_' + locale]"
                        class="list-group"
                        tag="ul"
                        v-bind="DragOptions"
                        @start="drag = true"
                        @end="drag = false"
                      >
                        <CCard v-for="(item, index) in ThemesData['Menu_' + locale]" :key="index">
                          <CCardHeader class="d-flex align-items-center">
                            <div class="flex-grow-1" style="cursor: pointer;" @click="(MenuAccordion = MenuAccordion === index ? false : index)">
                              {{ item.Name }}
                            </div>
                            <CButton size="sm" color="danger" @click="DeleteLocaleMenuItem(`${index}`, locale)">{{ $t('Global.Delete') }}</CButton>
                          </CCardHeader>
                          <CCollapse :show="MenuAccordion === index">
                            <CCardBody class="d-flex">
                              <CRow class="w-100">
                                <CCol>
                                  <CInput v-model="item.Name" :label="$t('System/Themes.Menu.Name')"/>
                                  <CInput v-model="item.SubTitle" :label="$t('System/Themes.Menu.SubTitle')"/>
                                  <CInput v-model="item.URL" :label="$t('System/Themes.Menu.URL')"/>
                                  <CSelect :value.sync="item.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                  <div class="w-100 d-flex flex-column my-2 mb-4">
                                    <label for="MenuSwitch">啟用選單</label>
                                    <CSwitch
                                      color="success"
                                      shape="pill"
                                      :checked.sync="item.Open"
                                      id="MenuSwitch"
                                    />
                                  </div>
                                  <CButton block color="info" @click="AddSubMenuItem(index, locale)">{{ $t('Global.Add') }}{{ $t('System/Themes.Menu.SubMenu') }}</CButton>
                                </CCol>
                                <CCol>
                                  <h5>{{ $t('System/Themes.Menu.SubMenu') }}</h5>
                                  <Draggable
                                    v-model="item.SubMenu"
                                    v-bind="DragOptions"
                                    @start="drag = true"
                                    @end="drag = false"
                                  >
                                    <CCard v-for="(subItem, subIndex) in item.SubMenu" :key="subIndex">
                                      <CCardHeader class="d-flex align-items-center">
                                        <div class="flex-grow-1" style="cursor: pointer;" @click="(SubMenuAccordion = SubMenuAccordion === `${index}-${subIndex}` ? false : `${index}-${subIndex}`)">
                                          {{ subItem.Name }}
                                        </div>
                                        <CButton color="danger" size="sm" @click="DeleteLocaleMenuItem(`${index}-${subIndex}`, locale)">{{ $t('Global.Delete') }}</CButton>
                                      </CCardHeader>
                                      <CCollapse :show="SubMenuAccordion === `${index}-${subIndex}`">
                                        <CCardBody class="d-flex flex-column">
                                          <CInput v-model="subItem.Name" :label="$t('System/Themes.Menu.Name')"/>
                                          <CInput v-model="subItem.URL" :label="$t('System/Themes.Menu.URL')"/>
                                          <CSelect :value.sync="subItem.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                          <label for="MenuSwitch">啟用選單</label>
                                          <CSwitch
                                            color="success"
                                            shape="pill"
                                            :checked.sync="subItem.Open"
                                            id="MenuSwitch"
                                          />
                                        </CCardBody>
                                      </CCollapse>
                                    </CCard>
                                  </Draggable>
                                </CCol>
                              </CRow>
                            </CCardBody>
                          </CCollapse>
                        </CCard>
                      </Draggable>
                    </CCardBody>
                    <CCardFooter class="d-flex justify-content-between">
                      <CButton color="info" class="px-4 mt-3 mr-1" @click="AddMenuItem(locale)">{{ $t('Global.Add') }}</CButton>
                      <CButton color="success" class="px-4 mt-3" @click="SetData('Menu_' + locale)">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardFooter>
                  </CCollapse>
                </CCard>
              </template>
            </CTab>
            <CTab v-if="Features.includes('Menu')" :title="$t('System/Themes.FooterNavigation')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Themes.FooterNavigation') }}
              </h5>
              <hr>

              <CCard>
                <CCardHeader class="d-flex align-items-center">
                  <div class="flex-grow-1" style="cursor: pointer;" @click="(PopAccordion = PopAccordion === 'Default' ? false : 'Default')">
                    {{ $t('Locale.Default') }}
                  </div>
                </CCardHeader>
                <CCollapse :show="PopAccordion === 'Default'">
                  <CCardBody v-if="ThemesData.FooterMenu">
                    <Draggable
                      v-model="ThemesData.FooterMenu"
                      class="list-group"
                      tag="ul"
                      v-bind="DragOptions"
                      @start="drag = true"
                      @end="drag = false"
                    >
                      <CCard v-for="(item, index) in ThemesData.FooterMenu" :key="index">
                        <CCardHeader class="d-flex align-items-center">
                          <div class="flex-grow-1" style="cursor: pointer;" @click="(FooterMenuAccordion = FooterMenuAccordion === index ? false : index)">
                            {{ item.Name }}
                          </div>
                          <CButton size="sm" color="danger" @click="DeleteFooterMenuItem(`${index}`)">{{ $t('Global.Delete') }}</CButton>
                        </CCardHeader>
                        <CCollapse :show="FooterMenuAccordion === index">
                          <CCardBody class="d-flex">
                            <CRow class="w-100">
                              <CCol>
                                <CInput v-model="item.Name" :label="$t('System/Themes.Menu.Name')"/>
                                <CInput v-model="item.SubTitle" :label="$t('System/Themes.Menu.SubTitle')"/>
                                <CInput v-model="item.URL" :label="$t('System/Themes.Menu.URL')"/>
                                <CSelect :value.sync="item.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                <div class="w-100 d-flex flex-column my-2 mb-4">
                                  <label for="MenuSwitch">啟用選單</label>
                                  <CSwitch
                                    color="success"
                                    shape="pill"
                                    :checked.sync="item.Open"
                                    id="MenuSwitch"
                                  />
                                </div>
                                <CButton block color="info" @click="AddFooterSubMenuItem(index)">{{ $t('Global.Add') }}{{ $t('System/Themes.Menu.SubMenu') }}</CButton>
                              </CCol>
                              <CCol>
                                <h5>{{ $t('System/Themes.Menu.SubMenu') }}</h5>
                                <Draggable
                                  v-model="item.SubMenu"
                                  v-bind="DragOptions"
                                  @start="drag = true"
                                  @end="drag = false"
                                >
                                  <CCard v-for="(subItem, subIndex) in item.SubMenu" :key="subIndex">
                                    <CCardHeader class="d-flex align-items-center">
                                      <div class="flex-grow-1" style="cursor: pointer;" @click="(FooterSubMenuAccordion = FooterSubMenuAccordion === `${index}-${subIndex}` ? false : `${index}-${subIndex}`)">
                                        {{ subItem.Name }}
                                      </div>
                                      <CButton color="danger" size="sm" @click="DeleteFooterMenuItem(`${index}-${subIndex}`)">{{ $t('Global.Delete') }}</CButton>
                                    </CCardHeader>
                                    <CCollapse :show="FooterSubMenuAccordion === `${index}-${subIndex}`">
                                      <CCardBody class="d-flex flex-column">
                                        <CInput v-model="subItem.Name" :label="$t('System/Themes.Menu.Name')"/>
                                        <CInput v-model="subItem.URL" :label="$t('System/Themes.Menu.URL')"/>
                                        <CSelect :value.sync="subItem.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                        <label for="MenuSwitch">啟用選單</label>
                                        <CSwitch
                                          color="success"
                                          shape="pill"
                                          :checked.sync="subItem.Open"
                                          id="MenuSwitch"
                                        />
                                      </CCardBody>
                                    </CCollapse>
                                  </CCard>
                                </Draggable>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCollapse>
                      </CCard>
                    </Draggable>
                  </CCardBody>
                  <CCardFooter class="d-flex justify-content-between">
                    <CButton color="info" class="px-4 mt-3 mr-1" @click="AddFooterMenuItem('default')">{{ $t('Global.Add') }}</CButton>
                    <CButton color="success" class="px-4 mt-3" @click="SetData('FooterMenu')">
                      {{ $t('Global.Update') }}
                    </CButton>
                  </CCardFooter>
                </CCollapse>
              </CCard>
              <template v-if="$store.state.user.permission.MutipleLocale">
                <CCard v-for="(locale, index) in $store.state.user.permission.Locales" :key="'FooterMenu_' + locale">
                  <CCardHeader class="d-flex align-items-center">
                    <div class="flex-grow-1" style="cursor: pointer;" @click="(PopAccordion = PopAccordion === index ? false : index)">
                      {{ $t('Locale.' + locale) }}
                    </div>
                  </CCardHeader>
                  <CCollapse :show="PopAccordion === index">
                    <CCardBody v-if="ThemesData['FooterMenu_' + locale]">
                      <Draggable
                        v-model="ThemesData['FooterMenu_' + locale]"
                        class="list-group"
                        tag="ul"
                        v-bind="DragOptions"
                        @start="drag = true"
                        @end="drag = false"
                      >
                        <CCard v-for="(item, index) in ThemesData['FooterMenu_' + locale]" :key="index">
                          <CCardHeader class="d-flex align-items-center">
                            <div class="flex-grow-1" style="cursor: pointer;" @click="(FooterMenuAccordion = FooterMenuAccordion === index ? false : index)">
                              {{ item.Name }}
                            </div>
                            <CButton size="sm" color="danger" @click="DeleteFooterLocaleMenuItem(`${index}`, locale)">{{ $t('Global.Delete') }}</CButton>
                          </CCardHeader>
                          <CCollapse :show="FooterMenuAccordion === index">
                            <CCardBody class="d-flex">
                              <CRow class="w-100">
                                <CCol>
                                  <CInput v-model="item.Name" :label="$t('System/Themes.Menu.Name')"/>
                                  <CInput v-model="item.SubTitle" :label="$t('System/Themes.Menu.SubTitle')"/>
                                  <CInput v-model="item.URL" :label="$t('System/Themes.Menu.URL')"/>
                                  <CSelect :value.sync="item.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                  <div class="w-100 d-flex flex-column my-2 mb-4">
                                    <label for="MenuSwitch">啟用選單</label>
                                    <CSwitch
                                      color="success"
                                      shape="pill"
                                      :checked.sync="item.Open"
                                      id="MenuSwitch"
                                    />
                                  </div>
                                  <CButton block color="info" @click="AddFooterSubMenuItem(index, locale)">{{ $t('Global.Add') }}{{ $t('System/Themes.Menu.SubMenu') }}</CButton>
                                </CCol>
                                <CCol>
                                  <h5>{{ $t('System/Themes.Menu.SubMenu') }}</h5>
                                  <Draggable
                                    v-model="item.SubMenu"
                                    v-bind="DragOptions"
                                    @start="drag = true"
                                    @end="drag = false"
                                  >
                                    <CCard v-for="(subItem, subIndex) in item.SubMenu" :key="subIndex">
                                      <CCardHeader class="d-flex align-items-center">
                                        <div class="flex-grow-1" style="cursor: pointer;" @click="(FooterSubMenuAccordion = FooterSubMenuAccordion === `${index}-${subIndex}` ? false : `${index}-${subIndex}`)">
                                          {{ subItem.Name }}
                                        </div>
                                        <CButton color="danger" size="sm" @click="DeleteFooterLocaleMenuItem(`${index}-${subIndex}`, locale)">{{ $t('Global.Delete') }}</CButton>
                                      </CCardHeader>
                                      <CCollapse :show="FooterSubMenuAccordion === `${index}-${subIndex}`">
                                        <CCardBody class="d-flex flex-column">
                                          <CInput v-model="subItem.Name" :label="$t('System/Themes.Menu.Name')"/>
                                          <CInput v-model="subItem.URL" :label="$t('System/Themes.Menu.URL')"/>
                                          <CSelect :value.sync="subItem.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                          <label for="MenuSwitch">啟用選單</label>
                                          <CSwitch
                                            color="success"
                                            shape="pill"
                                            :checked.sync="subItem.Open"
                                            id="MenuSwitch"
                                          />
                                        </CCardBody>
                                      </CCollapse>
                                    </CCard>
                                  </Draggable>
                                </CCol>
                              </CRow>
                            </CCardBody>
                          </CCollapse>
                        </CCard>
                      </Draggable>
                    </CCardBody>
                    <CCardFooter class="d-flex justify-content-between">
                      <CButton color="info" class="px-4 mt-3 mr-1" @click="AddFooterMenuItem(locale)">{{ $t('Global.Add') }}</CButton>
                      <CButton color="success" class="px-4 mt-3" @click="SetData('FooterMenu_' + locale)">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardFooter>
                  </CCollapse>
                </CCard>
              </template>
            </CTab>
            <CTab v-if="Features.includes('Style')" :title="$t('System/Themes.Style')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Themes.CSS') }}
              </h5>
              <hr>
              <prism-editor class="my-editor" v-model="ThemesData.Style.CSS" :tabSize="2" :highlight="highlighter" line-numbers />
              <div class="d-flex justify-content-between">
                <CButton color="success" class="px-4 mt-3" @click="SetData('Style')">{{ $t('Global.Update') }}</CButton>
              </div>
            </CTab>
          </CTabs>
          <CElementCover v-if="(Loading === true)" :opacity="0.75">
            <CSpinner color="success"/>
          </CElementCover>
        </CCardBody>
      </CCard>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" @OK="SetImage()" />
  </main>
</template>

<route>
{
  "meta": {
    "label": "佈景設定"
  }
}
</route>

<script>
import { Chrome as ColorPicker } from 'vue-color'

// import Prism Editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-css'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

export default {
  name: 'ThemeSetting',
  components: {
    Carousel: () => import('@/components/themes/Carousel'),
    MediaStore: () => import('@/components/public/Media'),
    WidgetsLocales: () => import('@/components/widgets/WidgetsLocales'),
    Draggable: () => import('vuedraggable'),
    ColorPicker,
    PrismEditor
  },
  layout: 'manage',
  data () {
    return {
      Accordion: null,
      ThemesData: {
        MarqueeConfig: {},
        Style: {},
        CarouselGroup: {},
        PopUp: {}
      },
      Loading: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {}
      },
      Features: [],
      SelectColor: {},
      ColorPalette: false,
      NowChooseLanguage: 'default',
      ChooseImageIndex: false,
      FieldName: '',
      Model: '',
      ChooseVideo: 'Youtube',
      VideoPlatformIcon: {
        Default: 'cil-video',
        Vimeo: 'cib-vimeo',
        Youtube: 'cib-youtube'
      },
      VideoPlatformList: {
        Default: 'https://',
        Vimeo: 'https://player.vimeo.com/video/{id}',
        Youtube: 'https://www.youtube.com/embed/{id}',
      },
      drag: false,
      ToggleStatus: [],
      LastToggleItem: '',
      MenuAccordion: '',
      FooterMenuAccordion: '',
      CarouselAccordion: '',
      PopAccordion: '',
      SubMenuAccordion: '',
      FooterSubMenuAccordion: '',
    }
  },
  computed: {
    TargetOption() {
      return [
        { value: '_self', label: this.$t('Global.SelfTarget')},
        { value: '_blank', label: this.$t('Global.BlankTarget')}
      ]
    },
    DragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  mounted() {
    this.Loading = true
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
      this.Loading = false
    }).catch((err) => {
      console.log(err)
      this.$Progress.fail()
      this.Loading = false
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      this.Features = PermissionSession.Features.System.Themes
      const LoadData = this.Features.map(type => {
        const Executes = []
        Executes.push(
          this.GetData(type)
        )
        if (type === 'Menu') {
          Executes.push(
            this.GetData('FooterMenu')
          )
        }
        if (PermissionSession.MutipleLocale) {
          switch (type) {
            case 'Carousel':
            case 'CarouselMobile':
            case 'CarouselBottom':
            case 'PopUp':
            case 'Menu':
              PermissionSession.Locales.forEach((locale) => {
                Executes.push(
                  this.GetData(type + '_' + locale)
                )
                if (type === 'Menu') {
                  this.GetData('FooterMenu_' + locale)
                }
              })
              break
          }
        }
        return Executes
      })
      return Promise.all(LoadData).then(() => {
        if (this.$store.state.user.permission.MutipleLocale) {
          const LangSchema = {
            Marquee: 'objectContainArray'
          }
          Object.keys(LangSchema).forEach((schema) => {
            if (this.ThemesData[schema]) {
              switch (LangSchema[schema]) {
                case 'objectContainArray':
                  Object.keys(this.ThemesData[schema]).forEach((subSchema) => {
                    if (!this.ThemesData[schema][subSchema].LanguageData) {
                      this.$set(this.ThemesData[schema][subSchema], 'LanguageData', {})
                    }
                    this.$store.state.user.permission.Locales.forEach((locale) => {
                      if (!this.ThemesData[schema][subSchema].LanguageData[locale]) {
                        this.$set(this.ThemesData[schema][subSchema].LanguageData, locale, {})
                      }
                    })
                  })
                  break
              }
            }
          })
          this.$store.state.user.permission.Locales.forEach((locale) => {
            if (!this.ThemesData['Carousel_' + locale]) {
              this.$set(this.ThemesData, 'Carousel_' + locale, [])
            }
            if (!this.ThemesData['CarouselMobile_' + locale]) {
              this.$set(this.ThemesData, 'CarouselMobile_' + locale, [])
            }
            if (!this.ThemesData['CarouselBottom_' + locale]) {
              this.$set(this.ThemesData, 'CarouselBottom_' + locale, [])
            }
            if (!this.ThemesData['PopUp_' + locale]) {
              this.$set(this.ThemesData, 'PopUp_' + locale, {})
            }
            if (!this.ThemesData['Menu_' + locale]) {
              this.$set(this.ThemesData, 'Menu_' + locale, [])
            }
            if (!this.ThemesData['FooterMenu_' + locale]) {
              this.$set(this.ThemesData, 'FooterMenu_' + locale, [])
            }
          })
          this.NowChooseLanguage = this.$store.state.user.permission.DefaultLocale || 'default'
        }
      }).catch((err) => {
        throw err
      })
    },
    GetData(type) {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/' + type,
        method: 'get'
      }).then(({data}) => {
        this.$set(this.ThemesData, type, data)
        if (type === 'MarqueeConfig') {
          this.SelectColor = this.ThemesData.MarqueeConfig.Color
        }
        return true
      }).catch((err) => {
        throw err
      })
    },
    PushData(type) {
      let Data
      switch (type) {
        case 'Marquee':
          Data = {
            Badge: '--',
            Text: '--',
            LanguageData: {}
          }
          this.$store.state.user.permission.Locales.forEach((locale) => {
            Data.LanguageData[locale] = {
              Badge: '--',
              Text: '--',
            }
          })
          this.ThemesData.Marquee.push(Data)
          break
      }
    },
    SyncLocaleData(type) {
      if (this.$store.state.user.permission.MutipleLocale && this.$store.state.user.permission.DefaultLocale !== 'default') {
        switch (type) {
          case 'Marquee':
            this.ThemesData.Marquee.forEach((item, index) => {
              this.ThemesData.Marquee[index].Badge = this.ThemesData.Marquee[index].LanguageData[this.$store.state.user.permission.DefaultLocale].Badge
              this.ThemesData.Marquee[index].Text = this.ThemesData.Marquee[index].LanguageData[this.$store.state.user.permission.DefaultLocale].Text
            })
            break
        }
      }
    },
    SetData(type) {
      const data = {
          [type]: this.ThemesData[type]
      }
      this.SyncLocaleData(type)
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/' + type,
        method: 'post',
        data
      }).then(() => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    OpenMediaStore(model, fieldName, index = false, ) {
      this.FieldName = fieldName
      this.Model = model
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = false
      if (index !== false) this.ChooseImageIndex = index
    },
    SetImage() {
      if (this.$store.state.user.permission.StaticDomain) {
        this.$set(this.ThemesData[this.Model], this.FieldName, {
          ...this.ThemesData[this.Model][this.FieldName],
          URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
          Type: 'images'
        })
        return true
      }
      this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
        this.$set(this.ThemesData[this.Model], this.FieldName, {
          ...this.ThemesData[this.Model][this.FieldName],
          URL,
          Type: 'images'
        })
      })
    },
    AddMenuItem(locale = 'default') {
      if (locale !== 'default') {
        this.ThemesData['Menu_' + locale].push({
          Name: `Menu#${this.ThemesData['Menu_' + locale].length + 1}`,
          URL: '',
          Target: '_self',
          Open: true,
          SubMenu: []
        })
        return true
      }
      this.ThemesData.Menu.push({
        Name: `Menu#${this.ThemesData.Menu.length + 1}`,
        URL: '',
        Target: '_self',
        Open: true,
        SubMenu: []
      })
    },
    AddSubMenuItem(key, locale = 'default') {
      if (locale !== 'default') {
        if (!this.ThemesData['Menu_' + locale][key].SubMenu) {
          this.$set(this.ThemesData['Menu_' + locale][key], 'SubMenu', [])
        }
        this.ThemesData['Menu_' + locale][key].SubMenu.push({
          Name: `SubMenu#${this.ThemesData['Menu_' + locale][key].SubMenu.length + 1}`,
          URL: '',
          Target: '_self',
          Open: true,
          SubMenu: []
        })
        return true
      }
      if (!this.ThemesData.Menu[key].SubMenu) {
        this.$set(this.ThemesData.Menu[key], 'SubMenu', [])
      }
      this.ThemesData.Menu[key].SubMenu.push({
        Name: `SubMenu#${this.ThemesData.Menu[key].SubMenu.length + 1}`,
        URL: '',
        Target: '_self',
        Open: true
      })
    },
    DeleteMenuItem(key) {
      const keys = key.split('-')
      if(keys.length === 2) {
        this.ThemesData.Menu[keys[0]].SubMenu.splice(keys[1], 1)
      } else {
        this.ThemesData.Menu.splice(keys[0], 1)
      }
    },
    DeleteLocaleMenuItem(key, locale = 'default') {
      if (locale !== 'default') {
        const keys = key.split('-')
        if(keys.length === 2) {
          this.ThemesData['Menu_' + locale][keys[0]].SubMenu.splice(keys[1], 1)
        } else {
          this.ThemesData['Menu_' + locale].splice(keys[0], 1)
        }
      }
    },

    AddFooterMenuItem(locale = 'default') {
      if (locale !== 'default') {
        this.ThemesData['FooterMenu_' + locale].push({
          Name: `FooterMenu#${this.ThemesData['FooterMenu_' + locale].length + 1}`,
          URL: '',
          Target: '_self',
          Open: true,
          SubMenu: []
        })
        return true
      }
      this.ThemesData.FooterMenu.push({
        Name: `FooterMenu#${this.ThemesData.FooterMenu.length + 1}`,
        URL: '',
        Target: '_self',
        Open: true,
        SubMenu: []
      })
    },
    AddFooterSubMenuItem(key, locale = 'default') {
      if (locale !== 'default') {
        if (!this.ThemesData['FooterMenu_' + locale][key].SubMenu) {
          this.$set(this.ThemesData['FooterMenu_' + locale][key], 'SubMenu', [])
        }
        this.ThemesData['FooterMenu_' + locale][key].SubMenu.push({
          Name: `FooterSubMenu#${this.ThemesData['FooterMenu_' + locale][key].SubMenu.length + 1}`,
          URL: '',
          Target: '_self',
          Open: true,
          SubMenu: []
        })
        return true
      }
      if (!this.ThemesData.FooterMenu[key].SubMenu) {
        this.$set(this.ThemesData.FooterMenu[key], 'SubMenu', [])
      }
      this.ThemesData.FooterMenu[key].SubMenu.push({
        Name: `FooterSubMenu#${this.ThemesData.FooterMenu[key].SubMenu.length + 1}`,
        URL: '',
        Target: '_self',
        Open: true
      })
    },
    DeleteFooterMenuItem(key) {
      const keys = key.split('-')
      if(keys.length === 2) {
        this.ThemesData.FooterMenu[keys[0]].SubMenu.splice(keys[1], 1)
      } else {
        this.ThemesData.FooterMenu.splice(keys[0], 1)
      }
    },
    DeleteFooterLocaleMenuItem(key, locale = 'default') {
      if (locale !== 'default') {
        const keys = key.split('-')
        if(keys.length === 2) {
          this.ThemesData['FooterMenu_' + locale][keys[0]].SubMenu.splice(keys[1], 1)
        } else {
          this.ThemesData['FooterMenu_' + locale].splice(keys[0], 1)
        }
      }
    },

    highlighter(code) {
      return highlight(code, languages.css, 'css') // languages.<insert language> to return html with markup
    }
  }
}
</script>
